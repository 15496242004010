import $ from 'jquery';
window.$ = window.jQuery = $;
import gsap from 'gsap';
import { Engine, Render, Runner, Bodies, Composite, World } from 'matter-js';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);
import Lenis from '@studio-freight/lenis'
import AOS from 'aos';
AOS.init({  duration: 1000, once: true, startEvent: 'load'});
window.addEventListener('DOMContentLoaded', function() {
  // Ensure the scroll position is set to the top when the page loads
  window.scrollTo(0, 0);
});

window.addEventListener('beforeunload', function() {
  // Reset the scroll position to the top before the page unloads
  window.scrollTo(0, 0);
});

function initAnimations(){
  if(window.innerWidth >=1024){
    const lenis = new Lenis()

    lenis.on('scroll', (e) => {
      // console.log(e)
    })

    lenis.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time)=>{
      lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)
    document.addEventListener("DOMContentLoaded", function() {
      const container = document.getElementById('matter-container');

      const engine = Engine.create();
      const world = engine.world;

      const render = Render.create({
        element: container,
        engine: engine,
        options: {
          width: 600, // Set width to 600px
          height: window.innerHeight,
          wireframes: false,
          background: 'transparent'
        }
      });

      Render.run(render);
      const runner = Runner.create();
      Runner.run(runner, engine);

      // Shape sizes
      const triangleSize = 125;
      const circleRadius = 100;
      const squareSize = 200;

      // Create shapes with different starting positions within the 600px container
      const shapes = [
        Bodies.polygon(100, -80, 3, triangleSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.polygon(300, -200, 3, triangleSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.polygon(500, -80, 3, triangleSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.circle(100, -200, circleRadius, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.circle(300, -80, circleRadius, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.circle(500, -200, circleRadius, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.rectangle(150, -400, squareSize, squareSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.rectangle(450, -400, squareSize, squareSize, { restitution: 0.6, render: { fillStyle: 'white' } })
      ];

      // Add shapes to the world
      World.add(world, shapes);

      // Create ground within the 600px container
      const ground = Bodies.rectangle(300, window.innerHeight, 600, 60, {
        isStatic: true,
        render: { fillStyle: 'transparent' }
      });

      // Create left and right walls within the 600px container
      const leftWall = Bodies.rectangle(-30, window.innerHeight / 2, 60, window.innerHeight, {
        isStatic: true,
        render: { fillStyle: 'transparent' }
      });

      const rightWall = Bodies.rectangle(630, window.innerHeight / 2, 60, window.innerHeight, {
        isStatic: true,
        render: { fillStyle: 'transparent' }
      });

      // Add ground and walls to the world
      World.add(world, [ground, leftWall, rightWall]);

      // Ensure the canvas resizes correctly and boundaries remain at the correct positions
      window.addEventListener('resize', () => {
        render.canvas.width = 600;
        render.canvas.height = window.innerHeight;
        Body.setPosition(ground, { x: 300, y: window.innerHeight });
        Body.setVertices(ground, [
          { x: 0, y: window.innerHeight },
          { x: 600, y: window.innerHeight },
          { x: 600, y: window.innerHeight + 60 },
          { x: 0, y: window.innerHeight + 60 }
        ]);
        Body.setPosition(leftWall, { x: -30, y: window.innerHeight / 2 });
        Body.setVertices(leftWall, [
          { x: -30, y: 0 },
          { x: 30, y: 0 },
          { x: 30, y: window.innerHeight },
          { x: -30, y: window.innerHeight }
        ]);
        Body.setPosition(rightWall, { x: 630, y: window.innerHeight / 2 });
        Body.setVertices(rightWall, [
          { x: 600, y: 0 },
          { x: 660, y: 0 },
          { x: 660, y: window.innerHeight },
          { x: 600, y: window.innerHeight }
        ]);
      });
    });

    const wrapper = document.querySelector(".wrapper");
    const splitTextSpans = document.querySelectorAll('.split-text span');

    function getScrollAmount() {
      let wrapperWidth = wrapper.scrollWidth;
      return -(wrapperWidth - window.innerWidth);
    }

// Create a master timeline for container animation
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".wrapper",
        start: "top top",
        end: () => `+=${getScrollAmount() * -1}`,
        pin: true,
        animation: tl,
        scrub: 1,
        invalidateOnRefresh: true,
        markers: false
      }
    });

// Add the scrolling animation to the container timeline
    tl.to(wrapper, {
      x: getScrollAmount,
      ease: "none"
    });
    let info = document.querySelector('.info');
// Add the text animation to the container timeline
    tl.fromTo(splitTextSpans, {
      opacity: 0,
      y: 200,
      rotation: -5
    }, {
      opacity: 1,
      y: 0,
      rotation: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '#matter-container',
        start: 'left left', // Adjust as needed
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 1,
        containerAnimation: tl,
      }
    });

    tl.fromTo('.number-spinner', {
      opacity:0,
      x:100,
    }, {
      opacity:1,
      x:0,
      scrollTrigger: {
        trigger: '.marker',
        start: 'left left',
        scrub: true,
        // markers: true,
        // id: 1,
        containerAnimation: tl,
      }
    })

    tl.to(info, {
      opacity:0,
      scrollTrigger: {
        trigger:'.wrapper',
        start: "left left",
        endTrigger: '.info', // Adjust as needed
        end: "left",
        scrub: true,
        markers: false, // Add markers to visualize the start and end points
        // id: 2,
        containerAnimation: tl,
      }
    })


//Marker Section
    let markerWrapper = document.querySelector('.marker-wrapper');
    let markerWrapperWidth = markerWrapper.offsetWidth;
    let text02 = document.querySelector('.text-02');
    console.log(markerWrapperWidth + ' Marker Wrapper Width');
    text02.style.paddingLeft = (markerWrapperWidth + 50) + 'px';
    tl.to('.marker-wrapper', {
      x: () => (markerWrapperWidth), // Move the image-wrapper in sync with scroll
      scrollTrigger: {
        trigger: markerWrapper,
        start: "left 5%",
        end: `+=${markerWrapperWidth}`, // Stick for a specific duration
        scrub: true,
        containerAnimation: tl,
        onUpdate: (self) => {
          const progress = self.progress;
          const translateX = markerWrapperWidth * progress;
          markerWrapper.style.transform = `translateX(${translateX}px)`;
        }
      }
    })

    //Text 03
    let text02Spans = document.querySelectorAll('.text-02 span:not(.expanded)');
    tl.fromTo(text02Spans, {
      opacity: 0,
      y: 200,
      rotation: -5
    }, {
      opacity: 1,
      y: 0,
      rotation: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.expanded',
        start: "left right", // When the left side of the element hits the right side of the viewport
        end: "left center", // Optional: when the left side reaches the center of the viewport
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 1,
        containerAnimation: tl,
      }
    });

    let expandedText = document.querySelector('.text-02 .expanded');
    tl.fromTo(expandedText, {
      opacity: 0,
      x: 200,
    }, {
      opacity: 1,
      x: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.expanded',
        start: "left right", // When the left side of the element hits the right side of the viewport
        end: "left center", // Optional: when the left side reaches the center of the viewport
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 1,
        containerAnimation: tl,
      }
    });

//Last span
    let lastElement = document.querySelector('.last');
    const numberSpinner = document.querySelector('.number-spinner ul');
    const maxScroll = numberSpinner.scrollWidth - numberSpinner.parentElement.clientWidth;
    tl.to('.last', {
      fontSize: '23.21875vw',
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.marker',
        start: "left center",
        containerAnimation: tl,
        scrub: true,
        onEnter: () => {
          lastElement.classList.add('new-font');
        },
        onLeaveBack: () => {
          lastElement.classList.remove('new-font');
        },
        onUpdate: (self) => {
          const progress = self.progress;
          const reverseProgress = progress > 0.5 ? (1 - progress) * 2 : progress * 2;
          const fontWeight = 100 + reverseProgress * (900 - 100);
          lastElement.style.fontVariationSettings = `'wght' ${fontWeight}`;

          // Set font style to italic when font weight reaches 900
          if (fontWeight < 300) {
            lastElement.style.fontStyle = 'italic';
          }else{
            lastElement.style.fontStyle = 'normal';
          }

          const spinnerProgress = progress > 0.5 ? (1 - progress) * 2 : progress * 2;
          const translateX = spinnerProgress * maxScroll;
          numberSpinner.style.transform = `translateX(-${translateX}px)`;

          // Set font style back to normal when the scroll is complete
          if (progress === 1) {
            lastElement.style.fontStyle = 'normal';
          }
        }
      }
    })

    // let newCog = document.querySelector('.new svg');
    // tl.to(newCog, {
    //   rotate: 360,
    //   scrollTrigger: {
    //     trigger:'.wrapper',
    //     start: "left left",
    //     endTrigger: '.wrapper',
    //     end: "right right",
    //     scrub: true,
    //     containerAnimation: tl,
    //   }
    //
    // })

//Expanded section
    function vwToPixels(vw) {
      return (vw * window.innerWidth) / 100;
    }

    let viewportWidth = 1920; // Example viewport width
    let vwValue = 30;
    let vwInPixels = ((vwValue * viewportWidth) / 100) * 2; // Should equal 576 pixels for a 1920px viewport
    let imageWrapper = document.querySelector('.image-wrapper');
    let imageWrapperWidth = imageWrapper.offsetWidth;
    let imageScroller = document.querySelector('.image-scroller-1');
    let imageScrollerWidth = (imageScroller.offsetWidth * 2);
    let imageScrollerWidthExtra = ((imageScroller.offsetWidth * 1) + vwInPixels);
    let imageScroller2 = document.querySelector('.image-scroller-2');
    let imageScroller2Width = imageScroller2.offsetWidth;


    tl.to(text02, {
      x: () => (imageScrollerWidth), // Move the image-wrapper in sync with scroll
      scrollTrigger: {
        trigger: imageWrapper,
        start: "left left",
        end: `+=${imageScrollerWidth}`, // Stick for a specific duration
        scrub: true,
        containerAnimation: tl,
        onUpdate:(self) => {
          const progress = self.progress;
          const translateX = imageScrollerWidth * progress;
          text02.style.transform = `translateX(${translateX}px)`;
        }
      }
    })

    tl.to(imageScroller, {
      x: () => (imageScrollerWidthExtra), // Move the image-wrapper in sync with scroll
      scrollTrigger: {
        trigger: imageScroller,
        start: "left left",
        end: `+=${imageScrollerWidthExtra}`, // Stick for a specific duration
        scrub: true,
        containerAnimation: tl,
        onUpdate:(self) => {
          const progress = self.progress;
          const translateX = imageScrollerWidthExtra * progress;
          imageScroller.style.transform = `translateX(${translateX}px)`;
        }
      }
    })
    let text03Big = document.querySelector('.text-03');
    let text03BigSpans = document.querySelectorAll('.big-title p');
    tl.fromTo(text03BigSpans, {
      opacity: 0,
      y: 200,
    }, {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: text03Big,
        start: 'left 80%', // Adjust as needed
        end: 'left left', // Adjust as needed
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 1,
        containerAnimation: tl,
      }
    });
//Text 03
    let text03 = document.querySelector('.text-03');
    let text03Spans = document.querySelectorAll('.text-03 span:not(.big-title)');
    tl.fromTo(text03Spans, {
      opacity: 0.5,
    }, {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: text03,
        start: 'left 80%', // Adjust as needed
        end: 'left left', // Adjust as needed
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 1,
        containerAnimation: tl,
      }
    });

    let text04 = document.querySelector('.text-04');
    let text04Spans = document.querySelectorAll('.text-04 span');
    tl.fromTo(text04Spans, {
      opacity: 0,
    }, {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: text04,
        start: 'left center', // Adjust as needed
        end: 'left left', // Adjust as needed
        // markers: true,
        // id: 99,
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 1,
        containerAnimation: tl,
      }
    });


    let text04Width = text04.offsetWidth;
    let text04Pin = document.querySelector('.text-04 .pin');
    const dateSpinner = document.querySelector('.date-spinner ul');
    const maxScrollDate = dateSpinner.scrollWidth - dateSpinner.parentElement.clientWidth;
    tl.to(text04, {
      // x: () => (text04Width), // Move the image-wrapper in sync with scroll
      scrollTrigger: {
        trigger: text04Pin,
        start: "left 2%",
        // end: `+=${text04Width}`, // Stick for a specific duration
        scrub: true,
        containerAnimation: tl,
        // markers: true,
        // id: 11,
        onUpdate:(self) => {
          const progress = self.progress;
          const translateX = text04Width * progress;
          // text04.style.transform = `translateX(${translateX}px)`;
          // Scroll the number spinner
          const translateXDate = progress * maxScrollDate;
          // console.log(translateX);
          if(translateXDate > 1700){
            dateSpinner.style.transform = `translateX(-1700px)`;
          }else{
            dateSpinner.style.transform = `translateX(-${translateXDate}px)`;
          }
        }
      }
    })

    // // Parallax effect for the individual elements
    // gsap.to(".flat-iron-text:nth-child(1)", {
    //   x: "-=100", // Move the first text slower than the container
    //   scrollTrigger: {
    //     trigger: ".image-home",
    //     start: "left right",
    //     end: "right left",
    //     scrub: true,
    //     containerAnimation: tl,
    //     markers: true,
    //     id: "image-marker"
    //   }
    // });

    gsap.to(".image-home img", {
      x: "-=180", // Move the image at a different speed
      scrollTrigger: {
        trigger: ".image-home",
        start: "left right",
        end: "right left",
        scrub: true,
        containerAnimation: tl,
      }
    });

    gsap.to(".flat-iron-text:nth-child(3)", {
      x: "-=200", // Move the second text even slower for a layered effect
      scrollTrigger: {
        trigger: ".image-home",
        start: "left right",
        end: "right left",
        scrub: true,
        containerAnimation: tl,
      }
    });



// Key Rotation
    tl.to(".key #bg", {
      rotate: 360,
      transformOrigin: "center center",
      ease: "none",
      scrollTrigger: {
        trigger:'.text-02 .image-wrapper',
        start: "left left",
        endTrigger: '.big-number-wrapper',
        end: "right right",
        scrub: true,
        containerAnimation: tl,
        // markers: true,
        // id: 999,
      }
    });
    let spinnerNumber = document.querySelector('.arrow');
    let bigNumber = document.querySelector('.big-number');

    tl.to(spinnerNumber, {
      x:-100,
        scrollTrigger: {
        trigger: bigNumber,
          start: "left right",
          end: "right center", // Increase the end value to slow down the animation
          scrub: true,
          toggleActions: "play none none none", // Automatically play the animation
          containerAnimation: tl,
        // markers: true,
        // id: 100
      },
    });
//Big Number

// Counting up animation
    tl.to(bigNumber, {
      x:100,
      snap: {innerHTML: 1},
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: bigNumber,
        start: "left center",
        end: "right center", // Increase the end value to slow down the animation
        scrub: true,
        toggleActions: "play none none none", // Automatically play the animation
        containerAnimation: tl,
        // markers: true,
        // id: 100
      },
      onUpdate: function() {
        const maxCount = 12;
        const speedUpFactor = 2; // Increase this factor to slow down the counting
        this.targets().forEach(target => {
          // Calculate the current count based on progress and speed factor
          const currentCount = Math.round(Math.min(maxCount, this.progress() * maxCount * speedUpFactor));

          // Set the target's innerHTML with the current count, formatted with leading zeros
          target.innerHTML = currentCount.toString().padStart(3, '0');
        });
      }
    });

//Text 05
    let text05 = document.querySelector('.text-05');
    let text05Spans = document.querySelectorAll('.text-05 span');
    tl.fromTo(text05Spans, {
      opacity: 0.5,
    }, {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: text05,
        start: 'left 80%', // Adjust as needed
        end: 'left 30%',
        scrub: true,
        containerAnimation: tl,
      }
    });

//Text 05
    let text06 = document.querySelector('.text-06');
    let text06Spans = document.querySelectorAll('.text-06 span');
    tl.fromTo(text06Spans, {
      opacity: 0.2,
    }, {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: text06,
        start: 'left 80%', // Adjust as needed
        end: 'left 30%',
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id: 15,
        containerAnimation: tl,
      }
    });

    let text06Width = text06.offsetWidth;
    tl.to(text06, {
      x: () => (text06Width), // Move the image-wrapper in sync with scroll
      scrollTrigger: {
        trigger: text06,
        start: "left 2%",
        end: `+=${text06Width}`, // Stick for a specific duration
        scrub: true,
        containerAnimation: tl,
        // markers: true,
        // id: 11,
        onUpdate:(self) => {
          const progress = self.progress;
          const translateX = text06Width * progress;
          text06.style.transform = `translateX(${translateX}px)`;
        }
      }
    })


//Travel Badges
    const travelBadges = document.querySelectorAll(".travel-badges img");

    gsap.fromTo(travelBadges, {
      opacity: 0,
      // y:200,
      x: 200,
      rotation: -5
    }, {
      opacity: 1,
      x: 0,
      y:0,
      rotation: 0,
      stagger: 0.6,
      duration: 4,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: text06,
        start: 'left left', // Adjust as needed
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        containerAnimation: tl,
      }
    });

    let text07 = document.querySelector('.text-07')
    const text07Spans = text07.querySelectorAll('.text-07 span');
    let text07Width = text07.offsetWidth;
    tl.to(text07, {
      x: () => (text07Width), // Move the image-wrapper in sync with scroll
      scrollTrigger: {
        trigger: text07,
        start: "left left",
        end: `+=${text07Width}`, // Stick for a specific duration
        scrub: true,
        containerAnimation: tl,
        // markers: true,
        // id: 11,
        onUpdate:(self) => {
          const progress = self.progress;
          const translateX = text07Width * progress;
          text07.style.transform = `translateX(${translateX}px)`;
          // Shorten the line based on scroll progress
          // const line = text07.querySelector('.line');
          // if (line) {
          //   const maxLineWidth = 750; // Max width in pixels
          //   const minLineWidth = 450; // Min width in pixels
          //   const widthRange = maxLineWidth - minLineWidth;
          //   const newLineWidth = minLineWidth + (widthRange * (1 - progress));
          //   line.style.width = `${newLineWidth}px`;
          // }

        }
      }
    })

    const line = text07.querySelector('.line');
    tl.fromTo(line, {
        width: '750px',  // Start with the width at 100%
      },
      {
        width: '200px',  // Animate the width down to 40%
        ease: 'power2.out',  // Easing for smooth animation
        duration: 1,  // Duration for the width change (relative to the scroll)
        scrollTrigger: {
          trigger: text07,
          start: "left left",
          end: `+=${text07Width}`, // Stick for a specific duration
          scrub: true,
          containerAnimation: tl,
        }
    })

    // tl.fromTo('.firstone', {
    //   opacity:1,
    // }, {
    //   x:"-100",
    //   opacity:0,
    //   scrollTrigger: {
    //     trigger: text07,
    //     start: "top top",
    //     scrub: true,
    //     containerAnimation: tl,
    //     // markers: true,
    //     // id: 11,
    //   }
    // })


// Calculate the total scroll distance for your timeline
    const totalScrollDistance = tl.scrollTrigger.end;
    const logoS = document.querySelector('.text-07 .logo');

// Add a new ScrollTrigger at the end of your timeline to change the body background color
    tl.to('body', {
      backgroundColor: 'white',
      scrollTrigger: {
        trigger: '.text-07',
        start: `left center`,
        end: "bottom bottom",
        scrub: true,
        // markers: true, // Add markers to visualize the start and end points
        // id:22,
        containerAnimation: tl,
        onUpdate:(self)=> {
          const progress = self.progress;
          // Change text color of text07 to black
          const text07 = document.querySelector('.text-07');
          text07.style.color = `rgb(${Math.floor(255 * (1 - progress))}, ${Math.floor(255 * (1 - progress))}, ${Math.floor(255 * (1 - progress))})`;

          // Invert the .logo element
          const logoB = document.querySelector('.logo-black');

          const invertValue = progress * 100;

          logoS.style.filter = `invert(${invertValue}%)`;
        },
        onLeave:() => {

        }
      }
    });





    gsap.fromTo('.fixed-one', {
      opacity:0,
      y:-100,
    },{
      opacity: 1,
      y: 20,
      scrollTrigger: {
        trigger: '.vertical-section',
        start: "top top",
        // markers: true,
        // id: 'vertical-section',
        scrub: true
    }
  });

 gsap.to(text07Spans, {
   opacity: 0,
   scrollTrigger: {
     trigger: '.content.desktop-only p:first-of-type',
     start: "top bottom",
     end: "bottom center",
     scrub: true,
     // markers: true,
     // id: 'vertical-section',
     // scrub: true
   }
 })


// Setup for the vertical scroll section
//     gsap.to('.vertical-section', {
//       scrollTrigger: {
//         trigger: '.vertical-section',
//         start: "top top",
//         pin: true,
//         pinSpacing: false,
//         scrub: true,
//         end: "bottom bottom", // Adjust the end value based on your content height
//         markers: true, // Add markers to visualize the start and end points
//         id:99,
//         onEnter: () => {
//           // Additional setup if needed when entering the final section
//           console.log('enter the dragon');
//         },
//         onLeave: () => {
//           // Additional setup if needed when leaving the final section
//         },
//         onUpdate:(self)=> {
//
//           const progress = 1 - self.progress; // Reverse the progress calculation
//           const minScale = 0.5;
//           const scale = minScale + progress * (1 - minScale); // Calculate the scale
//           console.log(progress)
//           // Apply the scale to the logo-black element
//           let logoBlack = document.querySelector('.logo-black');
//           if (logoBlack) {
//             logoBlack.style.transform = `scale(${scale})`;
//           }
//
//         }
//       }
//     });
//     const verticalSection = document.querySelector('.vertical-section');
//     const logo = document.querySelector('.logo-black');
//
//     ScrollTrigger.create({
//       trigger: verticalSection,
//       start: "top top",
//       end: "bottom top",
//       onEnter: () => {
//         verticalSection.classList.add('sticky');
//         logo.classList.add('shrink');
//       },
//       onLeaveBack: () => {
//         verticalSection.classList.remove('sticky');
//         logo.classList.remove('shrink');
//       }
//     });


    const content = document.querySelector('.content');

    ScrollTrigger.create({
      trigger: '.vertical-section',
      start: 'top bottom', // Adjust as needed
      endTrigger: '.content',
      end: 'bottom top', // Adjust as needed
      onEnter: () => content.classList.add('visible'),
      onLeave: () => content.classList.remove('visible'),
      onEnterBack: () => content.classList.add('visible'),
      onLeaveBack: () => content.classList.remove('visible'),
    });

    // const logoB = document.querySelector('.logo-black');
    //
    // tl.fromTo(logoB, {
    //   opacity:0,
    // }, {
    //   opacity:1,
    //   y:0,
    //   scrollTrigger: {
    //     trigger: logoB,
    //     start: "top center",
    //     end: "bottom bottom",
    //     scrub: true,
    //     // markers: true,
    //     // id: 33,
    //   }
    // })


  }
}

function mobileAnimations(){
  if(window.innerWidth < 1024){
    document.addEventListener("DOMContentLoaded", function() {
      const container = document.getElementById('matter-container-mobile');

      const engine = Engine.create();
      const world = engine.world;

      const render = Render.create({
        element: container,
        engine: engine,
        options: {
          width: window.innerWidth, // Set width to full width of the device
          height: window.innerHeight, // Set height to full height of the device
          wireframes: false,
          background: 'transparent'
        }
      });

      Render.run(render);
      const runner = Runner.create();
      Runner.run(runner, engine);

      // Updated shape sizes
      const triangleSize = 90;
      const circleRadius = 70;
      const squareSize = 98;

      // Create shapes with different starting positions within the full-width container
      const shapes = [
        Bodies.polygon(100, -80, 3, triangleSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.polygon(window.innerWidth / 2, -200, 3, triangleSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.polygon(window.innerWidth - 100, -80, 3, triangleSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.circle(100, -200, circleRadius, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.circle(window.innerWidth / 2, -80, circleRadius, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.circle(window.innerWidth - 100, -200, circleRadius, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.rectangle(window.innerWidth / 4, -400, squareSize, squareSize, { restitution: 0.6, render: { fillStyle: 'white' } }),
        Bodies.rectangle((window.innerWidth / 4) * 3, -400, squareSize, squareSize, { restitution: 0.6, render: { fillStyle: 'white' } })
      ];

      // Add shapes to the world
      World.add(world, shapes);

      // Position the ground farther off-screen (below the visible height)
      const ground = Bodies.rectangle(window.innerWidth / 2, window.innerHeight + 500, window.innerWidth, 60, {
        isStatic: true,
        render: { fillStyle: 'transparent' }
      });

      // Position the left and right walls farther outside the viewable area (optional)
      const leftWall = Bodies.rectangle(-30, window.innerHeight / 2, 60, window.innerHeight + 500, {
        isStatic: true,
        render: { fillStyle: 'transparent' }
      });

      const rightWall = Bodies.rectangle(window.innerWidth + 30, window.innerHeight / 2, 60, window.innerHeight + 500, {
        isStatic: true,
        render: { fillStyle: 'transparent' }
      });

      // Add ground and walls to the world
      World.add(world, [ground, leftWall, rightWall]);

      // Ensure the canvas resizes correctly and boundaries remain at the correct positions
      window.addEventListener('resize', () => {
        render.canvas.width = window.innerWidth;
        render.canvas.height = window.innerHeight;
        Body.setPosition(ground, { x: window.innerWidth / 2, y: window.innerHeight + 500 });
        Body.setVertices(ground, [
          { x: 0, y: window.innerHeight + 500 },
          { x: window.innerWidth, y: window.innerHeight + 500 },
          { x: window.innerWidth, y: window.innerHeight + 560 },
          { x: 0, y: window.innerHeight + 560 }
        ]);
        Body.setPosition(leftWall, { x: -30, y: window.innerHeight / 2 });
        Body.setVertices(leftWall, [
          { x: -30, y: 0 },
          { x: 30, y: 0 },
          { x: 30, y: window.innerHeight + 500 },
          { x: -30, y: window.innerHeight + 500 }
        ]);
        Body.setPosition(rightWall, { x: window.innerWidth + 30, y: window.innerHeight / 2 });
        Body.setVertices(rightWall, [
          { x: window.innerWidth, y: 0 },
          { x: window.innerWidth + 60, y: 0 },
          { x: window.innerWidth + 60, y: window.innerHeight + 500 },
          { x: window.innerWidth, y: window.innerHeight + 500 }
        ]);
      });
    });

    gsap.fromTo('.intro-text',
      { opacity: 0, y: 20 },  // Starting state: invisible and translated down by 20px
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out', delay: 2.5 }  // Ending state: fully visible and in its original position
    );

    // Scroll-triggered fade-out for .intro-text
    gsap.to('.intro-text', {
      opacity: 0,  // Fades out the element
      scrollTrigger: {
        pin: true,
        trigger: '.intro-text',  // Element that triggers the animation
        start: 'top top',  // Start fading out when the top of .intro-text hits the top of the viewport
        end: 'bottom top',  // Finish fading out when the bottom of .intro-text hits the top of the viewport
        scrub: true,  // Smoothly animates the opacity based on scroll position
        // markers: true,  // Adds visual markers to see where the animation starts and ends (for debugging)
        // id: 'intro-fade',  // Optional: Give this ScrollTrigger an ID for easier debugging
        onUpdate: (self) => {
          const progress = self.progress;
          gsap.set('.intro-text', { opacity: 1 - progress });
        },
      }
    });
// Scroll-triggered fade-in for .text-block-01 p elements
    gsap.fromTo('.text-block-01 p', {
        opacity: 0,
        y: 100,
      },
      {
        duration: 1,
        stagger: 0.3,
        y: 0,
        opacity: 1, // Fades in the elements
        scrollTrigger: {
          pin: true,
          trigger: '.second', // Element that triggers the animation
          start: 'top top', // Start when the top of .second hits the top of the viewport
          end: '+=1000', // Extend pinning duration (adjust value as needed)
          scrub: true, // Smoothly animates based on scroll position
          // markers: true, // Adds visual markers (for debugging)
          // id: 'text-01-fade-in', // Optional: ID for debugging
        }
      }
    );
    // Function to disable scroll
    function disableScroll() {
      document.body.style.overflow = 'hidden';
    }

// Function to enable scroll
    function enableScroll() {
      document.body.style.overflow = '';
    }




    gsap.to('.panel.third',  {
      scrollTrigger: {
        pin: true,
        trigger: '.panel.third',
        start: 'top top',
        end: '+=3000', // Extend pinning duration (adjust value as needed)
        scrub: true,
        onEnter: () => {
          playGif('#myGif');
        }
      }
    })

    // Pin the fourth section
    gsap.to('.panel.third-middle', {
      scrollTrigger: {
        pin: true,
        trigger: '.third-middle', // Element that triggers the pinning
        start: 'top top', // Start when the top of .fourth hits the top of the viewport
        end: '+=3000', // Extend pinning duration (adjust value as needed)
        scrub: true, // Smoothly animates based on scroll position
        // markers: true, // Adds visual markers (for debugging)
        // id: 'fourth-pin', // Optional: ID for debugging
        onEnter:() => {
          playGif('#expandedGif');
        }
      }
    });
    // Pin the fourth section
    gsap.to('.panel.fourth', {
      scrollTrigger: {
        pin: true,
        trigger: '.fourth', // Element that triggers the pinning
        start: 'top top', // Start when the top of .fourth hits the top of the viewport
        end: '+=2000', // Extend pinning duration (adjust value as needed)
        scrub: true, // Smoothly animates based on scroll position
        // markers: true, // Adds visual markers (for debugging)
        // id: 'fourth-pin', // Optional: ID for debugging
      }
    });



    // gsap.fromTo('.expanded-mobile', {
    //     opacity: 0,
    //     y:  100,
    //     fontVariationSettings: `'wght' 300`, // Start with font weight at 300
    //   },
    //   {
    //     y:0,
    //     opacity: 1,
    //     duration: 1, // Duration for the fade-in effect
    //     scrollTrigger: {
    //       trigger: '.expanded-mobile', // Element that triggers the animation
    //       start: 'top bottom', // Start when the top of .expanded-mobile is at the bottom of the viewport
    //       end: 'bottom top', // End when the bottom of .expanded-mobile is at the top of the viewport
    //       scrub: true, // Smoothly animates based on scroll position
    //       // markers: true, // Uncomment to see start and end points for debugging
    //       onUpdate: (self) => {
    //         const progress = self.progress; // Get the scroll progress (0 to 1)
    //         const fontWeight = 300 + progress * (900 - 300); // Calculate font weight from 300 to 900
    //         gsap.set('.expanded-mobile', { fontVariationSettings: `'wght' ${fontWeight}` });
    //       },
    //     }
    //   }
    // );

    // Create a timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.fourth',  // The section that triggers the entire animation sequence
        start: 'center center',  // Start when you're halfway through the .fourth section
        end: 'bottom top',  // End when the bottom of .fourth reaches the top of the viewport
        scrub: true,  // Smoothly animates based on scroll position
        // markers: true,  // Uncomment to see start and end points for debugging
      }
    });

// Animate the images first
    tl.fromTo('.images-mobile img', {
        opacity: 0,
        y: 100,  // Start with images 100px below their original position
      },
      {
        opacity: 1,
        y: 0,  // Move images to their original position
        duration: 1,  // Duration for each image's animation
        ease: 'power2.out',  // Easing for smooth animation
        stagger: 0.6,  // Stagger the start of each image animation by 0.6 seconds
      }
    )

// After the images, animate the text lines
    tl.fromTo('.big-creative p', {
        opacity: 0,
        y: 100,  // Start with text 100px below its original position
      },
      {
        opacity: 1,
        y: "-50",  // Move text to its original position
        duration: 1,  // Duration for each line's animation
        ease: 'power2.out',  // Easing for smooth animation
        stagger: 0.5,  // Stagger the start of each line animation by 0.5 seconds
      }, '+=0.5');  // Delay start by 0.5 seconds after images finish
  }

  // Timeline for the fifth panel with pinning and text animations
  const tlFifth = gsap.timeline({
    scrollTrigger: {
      trigger: '.panel.fifth',  // The section that triggers the animation sequence
      start: 'top top',  // Start pinning when the top of .panel.fifth hits the top of the viewport
      end: '+=3000',  // Extend the pinning duration as needed for the animation
      pin: true,  // Pin the .panel.fifth section
      scrub: true,  // Smoothly animates based on scroll position
      // markers: true,  // Uncomment to see start and end points for debugging
      onEnter:() => {
        playGif('#keyGif');
      }
    }
  });


  const maxScrollDate = document.querySelector('.date-spinner-mobile ul').scrollWidth - document.querySelector('.date-spinner-mobile').clientWidth;

  // Timeline for the sixth panel with pinning and parallax scroll animation
  const tlSixth = gsap.timeline({
    scrollTrigger: {
      trigger: '.panel.sixth',  // The section that triggers the animation sequence
      start: 'top top',  // Start pinning when the top of .panel.sixth hits the top of the viewport
      end: '+=3000',  // Extend the pinning duration as needed for the scrolling animation
      pin: true,  // Pin the .panel.sixth section
      scrub: true,  // Smoothly animates based on scroll position
      // markers: true,  // Uncomment to see start and end points for debugging
      onUpdate: (self) => {
        const progress = self.progress;  // Get the progress of the scroll (0 to 1)
        const translateXDate = progress * maxScrollDate;  // Calculate the translation based on progress

        if(translateXDate > "464.479"){
          document.querySelector('.date-spinner-mobile ul').style.transform = `translateX(-464.479px)`;  // Move the <ul> left

        }else{
          document.querySelector('.date-spinner-mobile ul').style.transform = `translateX(-${translateXDate}px)`;  // Move the <ul> left

        }

      }
    }
  });

// Animate each child element inside .image-home-mobile to move left at different speeds
  tlSixth.to('.image-home-mobile p:nth-child(1)', {
    x: '-90%',  // Move the first <p> slightly to the left
    ease: 'none',  // Linear movement
  }, 0);  // Start at the beginning

  tlSixth.to('.image-home-mobile img', {
    x: '-80%',  // Move the image more to the left
    ease: 'none',  // Linear movement
  }, 0);  // Start at the beginning

  tlSixth.to('.image-home-mobile p:nth-child(3)', {
    x: '-180%',  // Move the second <p> to the left, somewhere in between
    ease: 'none',  // Linear movement
  }, 0);  // Start at the beginning


  // Timeline for the seventh panel with pinning, number counting, and text animation
  gsap.timeline({
    scrollTrigger: {
      trigger: '.panel.seventh',  // The section that triggers the animation sequence
      start: 'top top',  // Start pinning when the top of .panel.seventh hits the top of the viewport
      end: '+=1500',  // Extend the pinning duration as needed
      pin: true,  // Pin the .panel.seventh section
      scrub: true,  // Smoothly animates based on scroll position
      // markers: true,  // Uncomment to see start and end points for debugging
      onUpdate: function(self) {
        const maxCount = 12;
        const slowDownFactor = 1; // Adjust this factor to slow down the counting
        const count = Math.round(self.progress * maxCount / slowDownFactor).toString().padStart(3, '0');
        document.querySelector('.big-number-mobile').innerHTML = count;
      }
    }
  })

    .fromTo('.panel.seventh p', {
        opacity: 0,
        y: 50,  // Start with the text 50px below its original position
      },
      {
        opacity: 1,
        y: 0,  // Move the text to its original position
        duration: 1,  // Duration for the animation
        ease: 'power2.out',  // Easing for smooth animation
        stagger: 0.3,  // Stagger the start of each <p> element if there are multiple
      }, 0);  // Start the text animation at the same time as the number counting

  gsap.timeline({
    scrollTrigger: {
      trigger: '.panel.eighth',  // The section that triggers the animation sequence
      start: 'top top',  // Start pinning when the top of .panel.eighth hits the top of the viewport
      end: '+=2000',  // Extend the pinning duration as needed
      pin: true,  // Pin the .panel.eighth section
      scrub: true,  // Smoothly animates based on scroll position
      // markers: true,  // Uncomment to see start and end points for debugging
    }
  })

    .fromTo('.panel.eighth p', {
        y: 100,  // Start with the text 100px below its original position
        opacity: 0,  // Start with the text invisible
      },
      {
        y: 0,  // Move the text to its original position
        opacity: 1,  // Fade the text in
        duration: 1,  // Duration for the animation
        ease: 'power2.out',  // Easing for smooth animation
        stagger: 0.3,  // Stagger the start of each <p> element by 0.3 seconds
      })

    .fromTo('.panel.eighth .tags img', {
        x: '100%',  // Start with the images off-screen to the right
        y: 50,      // Start with the images slightly below their final position
        opacity: 0,  // Start with the images invisible
      },
      {
        x: '0%',  // Move the images off-screen to the left
        y: 0,       // Move the images to their original vertical position
        opacity: 1,  // Fade the images in as they move across the screen
        ease: 'power2.out',  // Easing for smooth animation
        duration: 1.5,  // Duration for the movement (relative to the scroll)
        stagger: 0.3,  // Stagger the start of each image's animation by 0.3 seconds
      });

  // Timeline for the eighth panel with pinning and flowing images animation
  gsap.timeline({
    scrollTrigger: {
      trigger: '.panel.ninth',  // The section that triggers the animation sequence
      start: 'top top',  // Start pinning when the top of .panel.eighth hits the top of the viewport
      end: '+=4000',  // Extend the pinning duration as needed
      pin: true,  // Pin the .panel.eighth section
      scrub: true,  // Smoothly animates based on scroll position
      // markers: true,
      // id: 'Panel'
    }
  })
//
    .fromTo('.panel.ninth p', {
        y: 100,  // Start with the text 100px below its original position
        opacity: 0,  // Start with the text invisible
      },
      {
        y: 0,  // Move the text to its original position
        opacity: 1,  // Fade the text in
        duration: 1,  // Duration for the animation
        ease: 'power2.out',  // Easing for smooth animation
        stagger: 0.3,  // Stagger the start of each <p> element by 0.3 seconds
      })

    .fromTo('.panel.ninth .co-logo-wrap .line', {
        width: '100%',  // Start with the width at 100%
      },
      {
        width: '10%',  // Animate the width down to 40%
        ease: 'power2.out',  // Easing for smooth animation
        duration: 1,  // Duration for the width change (relative to the scroll)
      })
.to('.panel.ninth', {
    filter: 'invert(100%)',  // Invert the colors of the entire panel
    ease: 'power2.out',  // Easing for smooth animation
    duration: 1,  // Duration for the inversion (relative to the scroll)
  }, '-=0.5');  // Start the inversion slightly before the end of the width anima

  function handleLogoSwitch(){
    let secondLogo = document.querySelector('.secondary-logo');
    let mainLogo = document.querySelector('.main-logo');

    mainLogo.classList.add('sticky')
    secondLogo.classList.add('show')

  }
  function reverseHandleLogoSwitch(){
    let secondLogo = document.querySelector('.secondary-logo');
    let mainLogo = document.querySelector('.main-logo');

    mainLogo.classList.remove('sticky')
    secondLogo.classList.remove('show')
  }
// Make the logo sticky when it reaches the top of the viewport
  ScrollTrigger.create({
    trigger: '.footer-block',
    start: 'top center',  // When the top of the .co-logo-wrap hits the top of the viewport
    endTrigger: '.footer-block',
    end: 'bottom bottom',
    onEnter: () => handleLogoSwitch(),
    onLeaveBack: () => reverseHandleLogoSwitch(),
    // markers:true,
    // id:'sticky-logo'
  });
  // Timeline for the tenth panel with pinning and scrolling text animation
  gsap.timeline({
    scrollTrigger: {
      trigger: '.panel.tenth',  // The section that triggers the animation sequence
      start: 'top top',
      end: '+=1500',  // Extend the duration as needed
      pin: true,  // Pin the .panel.tenth section
      scrub: true,  // Smoothly animates based on scroll position
      // markers: true,  // Uncomment to see start and end points for debugging
      // id: 'start-10'
    }
  })
    .fromTo('.panel.tenth p', {
        opacity: 0,
        y: 50,  // Start with the text 50px below its original position
      },
      {
        opacity: 1,
        y: 0,  // Move the text to its original position
        duration: 1,  // Duration for the animation
        ease: 'power2.out',  // Easing for smooth animation
        stagger: 0.5,  // Stagger the start of each <p> element by 0.5 seconds
      });

}

// Initialize animations on load and on resize

initAnimations();

mobileAnimations();
let resizeTimeout;
// Function to play the GIF
function playGif(gifSelector) {
  const gifElement = document.querySelector(gifSelector);

  // Check if the element has the data-src attribute
  const gifDataSrc = gifElement.getAttribute('data-src');

  if (gifDataSrc) {
    // Set the src to the value of data-src to play the GIF
    gifElement.src = gifDataSrc;
  } else {
    console.warn('No data-src attribute found on the GIF element');
  }
}
window.addEventListener('resize', () => {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(initAnimations, 500);
});

// window.addEventListener('resize', () => {
//   clearTimeout(resizeTimeout);
//   resizeTimeout = setTimeout(mobileAnimations, 500);
// });

window.addEventListener('load', function() {
  document.body.classList.add('loaded');
});
